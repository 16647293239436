<script setup>
import { computed, ref } from 'vue';


const model = defineModel()

const props = defineProps({
  checkedValue: {
    default: 1,
  },
  uncheckedValue: {
    default: 0,
  },
  name: {
    type: String,
    default: '',
  },
});

const internalValue = ref(model.value ? props.checkedValue : props.uncheckedValue);

const proxyChecked = computed({
  get() {
    return model.value === props.checkedValue;
  },

  set(val) {
    internalValue.value = val ? props.checkedValue : props.uncheckedValue
    model.value = internalValue.value
  },
});
</script>

<template>
    <input
        :name="name"
        type="checkbox"
        :value="internalValue"
        v-model="proxyChecked"
        class="rounded border-stone-300 dark:border-gray-700 text-region-forest shadow-sm focus:ring-region-forest"
    />
</template>
